import { Trans } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Facts from '../facts';

const History = () => {
  return (
    <div className="flex flex-col pt-[2.875rem] pb-[2.5rem]  lg:py-[3.75rem] text-white">
      <div className="z-30 lg:max-w-[33.75rem]">
        <h1 className="text-white lg:text-[5rem] lg:leading-[4.95rem] xl:text-[5.625rem] xl:leading-[5.313rem] ">
          <Trans>Multiverse of cyber ranges</Trans>
        </h1>
        <p className="mt-[1.75rem] lg:mt-[7.5rem]">
          <Trans>
            Foundation CR14 was established by the Estonian Ministry of Defence
            on the 1st of January 2021 and is based on more than 10 years of
            military-grade cyber range experience in cybersecurity training,
            exercises, testing, validation and experimentation.
          </Trans>
        </p>
      </div>
      <div className="mt-[2rem] lg:mt-[3.75rem] z-30">
        <Facts />
      </div>
    </div>
  );
};

export default History;
