import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import History from '../components/landing/history';
import Base from '../components/base';
import DataCentres from '../components/landing/dataCentres';
import CoreValues from '../components/landing/coreValues';
import StrategicPartners from '../components/landing/startegicPartners';
import Teaser from '../components/insights/teaser';
import { InsightPreview } from '../types';
import { formatRawInsightPreview } from '../utilities';
import Sides from '../components/ranges/sides';
import SEO from '../components/seo';

const Index = ({
  data: {
    allMarkdownRemark: { nodes },
  },
  location,
}: PageProps<Queries.IndexPageInsightsQuery>) => {
  const insights: InsightPreview[] = nodes.map(formatRawInsightPreview);
  return (
    <Base location={location}>
      <div className="pt-[3.5rem] relative lg:rounded-lg overflow-hidden">
        <video
          autoPlay
          loop
          muted
          playsInline
          className=" object-cover w-[100%] h-[100%] inset-0 absolute -z-1"
        >
          <source src="/videos/background.webm" type="video/webm; codecs=vp9" />
          <source src="/videos/background.mp4" type="video/mp4; codecs=hevc" />
          <source src="/videos/background.m4v" type="video/mp4" />
          <Trans>Your browser does not support the video tag.</Trans>
        </video>
        <div className="absolute inset-0 bg-gradient-to-r from-black from-40% via-transparent to-transparent space-y-4  z-20  h-[100%] w-[100%]" />
        <Sides>
          <History />
        </Sides>
      </div>
      <div className="bg-black lg:rounded-lg">
        <div className="pt-[6.375rem] lg:pt-[8.75rem] pb-[5.625rem] lg:pb-[8.75rem]">
          <Sides>
            <DataCentres />
          </Sides>
        </div>
      </div>
      <div className="bg-primary lg:rounded-lg">
        <div className="pt-[6.375rem] lg:pt-[8.75rem] pb-[5.625rem] lg:pb-[8.75rem]">
          <Sides>
            <h2 className="text-black my-0">
              <Trans>Noteworthy Insights</Trans>
            </h2>
            <div className="text-black mt-[3.75rem] lg:mt-[5rem]">
              <Teaser black insights={insights} />
            </div>
          </Sides>
        </div>
      </div>

      <Sides>
        <div className="text-black pt-[5.625rem] lg:pt-[8.75rem]">
          <CoreValues />
        </div>
      </Sides>
      <Sides>
        <div className="text-black pt-[5.625rem] lg:pt-[8.75rem] pb-[5.625rem] lg:pb-[8.75rem]">
          <StrategicPartners />
        </div>
      </Sides>
    </Base>
  );
};

export default Index;

export const pageQuery = graphql`
  query IndexPageInsights($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "insights" } }
        frontmatter: { draft: { eq: false } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD.MM.YYYY")
          title
          alttext
          summary
          thumbnail {
            publicURL
          }
          tags {
            name
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO />;
