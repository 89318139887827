import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import Info from '../info';
import SmallRangeCard from './smallRangeCard';

const DataCentres = () => {
  const { t } = useI18next();
  return (
    <>
      <Info title={t('High-performance data centres')}>
        <p>
          <Trans>
            Cyber Range is a unique software-based virtual environment used for
            cybersecurity training and the development of dual-use innovative
            technologies using cyberspace.
          </Trans>
        </p>
      </Info>
      <div className="mt-[3.75rem] lg:mt-[5rem] grid gap-[3.575rem] lg:gap-[5rem] grid-cols-1 lg:grid-cols-2">
        <SmallRangeCard
          id="open-cyber-range"
          gradientFrom="from-open-cr"
          videoURL="/videos/video_OCR"
          title={t('Open Cyber Range')}
          intro={t(
            `OCR is a virtual environment used for cybersecurity training and technology testing and development. The CR14's OCR platform will include tools and products that are not part of standard cloud service.`
          )}
        />
        <SmallRangeCard
          id="estonian-cyber-range"
          gradientFrom="from-national-cr"
          videoURL="/videos/video_ECR"
          title={t('Estonian Cyber Range')}
          intro={t(
            'The Estonian national CR is based on more than ten years of operating experience, arguably being one of the earliest establishments of its kind globally.'
          )}
        />
        <SmallRangeCard
          id="nato-cyber-range"
          gradientFrom="from-nato-cr"
          videoURL="/videos/video_NCR"
          title={t('NATO Cyber Range')}
          intro={t(
            'NATO CR is a platform and data centre enabling NATO to conduct its largest cyber defence exercises and training, such as the Cyber Coalition and the Coalition Warrior Interoperability exercise (CWIX). CR14 is proud of the opportunity to serve the development of NATO’s capacity in this area since 2014 and to support Allies in achieving better preparedness for cyber-attacks.'
          )}
        />
        <SmallRangeCard
          id="classified-cyber-range"
          gradientFrom="from-classified-cr"
          videoURL="/videos/video_CCR"
          title={t('Classified Cyber Range')}
          intro={t(
            'Our mission is to explore the art of cybersecurity which might sometimes extend beyond the limits of unclassified.'
          )}
        />
      </div>
    </>
  );
};

export default DataCentres;
