import * as React from 'react';
import { Fact } from '../../types';

const Carousel = ({
  facts,
  blackButton,
}: {
  facts: Fact[];
  blackButton?: boolean;
}) => {
  return (
    <div
      className={`p-[1.25rem] border ${
        blackButton ? 'border-black' : ''
      } border-solid rounded-lg  space-x-5 flex justify-start`}
    >
      <div
        id="fact-carousel"
        className="carousel slide relative flex self-start"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-start p-0 -mb-4 pt[1rem]">
          {facts.map(({ ariaDescription }, index) => (
            <button
              key={ariaDescription}
              type="button"
              data-bs-target="#fact-carousel"
              data-bs-slide-to={index}
              className={`active rounded-[0.375rem] ${
                blackButton ? 'bg-black' : ''
              }`}
              aria-current="true"
              aria-label={ariaDescription}
            />
          ))}
        </div>
        <div className="carousel-inner relative w-full overflow-hidden ">
          {facts.map(({ title, value, tick }, index) => (
            <div
              key={title}
              className={`carousel-item ${
                index === 0 ? 'active' : ''
              } float-left w-full`}
            >
              <div>
                <h2 className="my-0">
                  {value} {tick && <sup className="text-primary ">+</sup>}
                </h2>
                <p className="my-0">{title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
