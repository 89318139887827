import { useI18next } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { Fact } from '../types';
import Carousel from './landing/carousel';

const Facts = ({ blackBorder = false }: { blackBorder?: boolean }) => {
  const { t } = useI18next();
  const facts: Fact[] = [
    {
      title: t('Years of military-grade cyber range experience'),
      value: '11',
      ariaDescription: t('Experience'),
      tick: true,
    },
    {
      title: t('Top-level #cyberrangers in our team'),
      value: '49',
      ariaDescription: t('Our Team'),
    },
    {
      title: t('Cyber experts trained in a year'),
      value: '3500',
      ariaDescription: t('Cyber experts trained'),
      tick: true,
    },
    {
      title: t('Unique training grounds for cyber defence'),
      value: '4',
      ariaDescription: t('Unique training ground'),
    },
  ];

  return (
    <>
      <div className="lg:hidden z-40">
        <Carousel blackButton={blackBorder} facts={facts} />
      </div>
      <div
        className={`hidden w-full lg:grid p-[1.25rem] grid-cols-4 border border-solid border-white/[.33] ${
          blackBorder ? 'border-black' : ''
        } rounded-lg space-x-5`}
      >
        {facts.map(({ value, title, tick }) => (
          <div key={title}>
            <h2 className="my-0 whitespace-nowrap lg:text-[3.1rem] lg:leading-[3.2rem] xl:text-6xl ">
              {value} {tick && <sup className="text-primary">+</sup>}
            </h2>
            <p className="my-0 lg:text-lg xl:text-xl">{title}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default Facts;
