import { Link } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

const SmallCard = ({
  title,
  titleSize = 'large',
  videoURL,
  gradientFrom,
  children,
  link,
  externalLink = false,
}: {
  title: string;
  titleSize?: 'small' | 'large';
  videoURL?: string;
  gradientFrom?: string;
  children?: React.ReactNode;
  link?: string;
  externalLink?: boolean;
}) => {
  const card = (
    <div className="relative card flex flex-col lg:flex-col group overflow-hidden h-[26.25rem] lg:h-[44.375rem]">
      {videoURL && (
        <video
          autoPlay
          muted
          loop
          playsInline
          className="z-0 absolute object-cover w-[100%] h-[100%] inset-0 lg:invisible lg:group-hover:visible rounded-[10px]"
        >
          <source src={`${videoURL}.webm`} type="video/webm; codecs=vp9" />
          <source src={`${videoURL}.mp4`} type="video/mp4; codecs=hevc" />
          <source src={`${videoURL}.m4v`} type="video/mp4" />
        </video>
      )}
      {gradientFrom && (
        <div
          className={`absolute w-full h-full inset-0 bg-gradient-to-b to-transparent ${gradientFrom} overflow-hidden rotate-180 lg:invisible lg:group-hover:visible rounded-[10px]`}
        />
      )}
      <div className="absolute px-[1.25rem] py-[1.875rem] lg:p-[3.75rem] inset-0 flex flex-col justify-between">
        {titleSize === 'large' && <h2 className="mb-8 lg:mb-12">{title}</h2>}
        {titleSize === 'small' && <h3 className="mb-8 lg:mb-12">{title}</h3>}
        {children}
      </div>
    </div>
  );

  if (link) {
    if (externalLink) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          className="no-underline text-white"
        >
          {card}
        </a>
      );
    }
    return (
      <Link to={link} className="no-underline text-white">
        {card}
      </Link>
    );
  }

  return card;
};

export default SmallCard;
