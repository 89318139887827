import * as React from 'react';
import SmallCard from '../smallCard';

const SmallRangeCard = ({
  id,
  title,
  videoURL,
  gradientFrom,
  intro,
}: {
  id: string;
  title: string;
  videoURL: string;
  gradientFrom: string;
  intro: string;
}) => (
  <SmallCard
    link={`/ranges#${id}`}
    gradientFrom={gradientFrom}
    title={title}
    videoURL={videoURL}
  >
    <p>{intro}</p>
  </SmallCard>
);

export default SmallRangeCard;
