import { useI18next, Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Partner } from '../../types';

const StrategicPartners = () => {
  const { t } = useI18next();
  const partners: Partner[] = [
    {
      homePage: 'https://mil.ee/',
      logoLink: '/partners/KV.svg',
      logoAlt: t('Estonian Defence Forces logo'),
    },
    {
      homePage: 'https://kaitseministeerium.ee/et',
      logoLink: '/partners/KAMIN.svg',
      logoAlt: t('Ministry of Defence of Estonia logo'),
    },
    {
      homePage: 'https://ccdcoe.org/',
      logoLink: '/partners/CCDCOE.svg',
      logoAlt: t('CCDCOE logo'),
    },
    {
      homePage: 'https://itl.ee/',
      logoLink: '/partners/ITL.svg',
      logoAlt: t('ITL logo'),
    },
    {
      homePage: 'https://www.eucybernet.eu/',
      logoLink: '/partners/EUCNET.svg',
      logoAlt: t('EUCNET logo'),
    },
  ];

  const secondRow: Partner[] = [
    {
      homePage: 'https://www.kaitseinvesteeringud.ee/',
      logoLink: '/partners/rkik.svg',
      logoAlt: t('RKIK logo'),
    },
  ];

  return (
    <div className="flex flex-col justify-center gap-[2.5rem]">
      <span className="tag self-center">
        <Trans>CR14 strategic partners</Trans>
      </span>
      <div className="self-center grid grid-cols-2 gap-[2rem] lg:flex">
        {partners.map(({ homePage, logoAlt, logoLink }) => (
          <a key={homePage} href={homePage}>
            <img src={logoLink} alt={logoAlt} />
          </a>
        ))}
      </div>
      <div className="self-center grid grid-cols-2 gap-[2rem] lg:flex">
        {secondRow.map(({ homePage, logoAlt, logoLink }) => (
          <a key={homePage} href={homePage}>
            <img src={logoLink} alt={logoAlt} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default StrategicPartners;
