import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';

const CoreValues = () => {
  const { t } = useI18next();
  const values = ['Client-oriented', 'Reliable', 'Accessible', 'Bridging'].map(
    (value) => t(value)
  );

  const [isVisible, setIsVisible] = useState(false);

  const valueElements = useRef([]);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            if (!isVisible && index === 0) {
              setIsVisible(true);
            } else {
              setTimeout(() => {
                const { target } = entry;
                if (target) {
                  target.style.opacity = '1';
                  target.style.transform = 'translateY(0)';
                }
              }, (index - (isVisible ? 1 : 0)) * 1500);
            }
          } else {
            const { target } = entry;
            if (target) {
              target.style.opacity = '0';
              target.style.transform = 'translateY(-10px)';
            }
          }
        });
      },
      {
        threshold: 0.7,
      }
    );

    valueElements.current.forEach((element) => {
      observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [isVisible]);

  return (
    <>
      <div className="flex flex-col justify-center">
        <span className="tag flex self-center mb-[2.125rem]">
          {t('Our core values')}
        </span>
        <div className="self-center text-center">
          {values.map((value, index) => (
            <h1
              className="mt-[1rem]"
              key={value}
              ref={(el) => {
                valueElements.current[index] = el;
              }}
              style={{
                opacity: isVisible || index === 0 ? '1' : '0',
                transform:
                  isVisible || index === 0
                    ? 'translateY(0)'
                    : 'translateY(+50px)',
                transition: 'opacity 1.3s ease, transform 1.3s ease',
                color:
                  index === 0 || (isVisible && index !== 0)
                    ? 'black'
                    : '#CCCCCC',
              }}
            >
              {value}
            </h1>
          ))}
        </div>
      </div>
      <hr className="mt-[1.875rem] border-black" />
    </>
  );
};

export default CoreValues;
